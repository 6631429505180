@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

/* 
body {
  font-family: "Jost", sans-serif;
} */

.svg-container:hover svg {
  fill: #fbb033; /* Change the fill color of the SVG icon when the parent div is hovered */
}

.rdt_TableHeader {
  white-space: normal;
  word-wrap: break-word;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  /* color: white; */
  filter: brightness(0) invert(1);
  /* opacity: 1;
  display: block;
  background: url(https://mywildalberta.ca/images/GFX-MWA-Parks-Reservations.png)
    no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin; */
}

.custom-time-input::-webkit-datetime-edit-text {
  color: white; /* Change the color of the text */
}

.custom-time-input::-webkit-datetime-edit-hour-field,
.custom-time-input::-webkit-datetime-edit-minute-field,
.custom-time-input::-webkit-datetime-edit-ampm-field {
  color: white; /* Change the color of the time symbols */
}

input[type="time"]::-webkit-calendar-picker-indicator {
  /* filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg)
    brightness(95%) contrast(80%); */
  filter: brightness(0) invert(1);
}

.dot-leaders {
  display: flex;
  align-items: end;
}

.dot-leaders span {
  /* align-self: end; */
  margin-bottom: 5px;
  margin-left: 3px;
  margin-right: 3px;
  white-space: nowrap;
}

.dot-leaders .dot-line {
  flex-grow: 1;
  border-bottom: 1px dotted white;
}
@media screen and (min-width: 1440px) {
  .Navbar {
    display: block;
  }
}

@media screen and (min-width: 700px) and (max-width: 1440px) {
  .Navbar {
    display: block;
  }
}

@media screen and (max-width: 700px) {
  .Navbar {
    display: none;
  }
}

.dataTables_wrapper {
  color: white;
}

.dataTables_wrapper select,
.dataTables_wrapper .dataTables_filter input {
  color: #4a5568;
  /* color: white; */
  /*text-gray-700*/
  padding-left: 1rem;
  /*pl-4*/
  padding-right: 1rem;
  /*pl-4*/
  padding-top: 0.5rem;
  /*pl-2*/
  padding-bottom: 0.5rem;
  /*pl-2*/
  line-height: 1.25;
  /*leading-tight*/
  border-width: 2px;
  /*border-2*/
  border-radius: 0.25rem;
  border-color: #edf2f7;
  /*border-gray-200*/
  background-color: #edf2f7;
  /* background-color: black; */
  /*bg-gray-200*/
}

/*Row Hover*/
table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
  /* background-color: #ebf4ff; */
  background-color: #fbb033;
  /*bg-indigo-100*/
}

/*Pagination Buttons*/
.dataTables_wrapper .dataTables_paginate .paginate_button {
  font-weight: 700;
  /*font-bold*/
  border-radius: 0.25rem;
  /*rounded*/
  border: 1px solid transparent;
  /*border border-transparent*/
}

/*Pagination Buttons - Current selected */
.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  color: #fff !important;
  /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  /*shadow*/
  font-weight: 700;
  /*font-bold*/
  border-radius: 0.25rem;
  /*rounded*/
  /* background: #667eea !important; */
  background: #667eea !important;
  /*bg-indigo-500*/
  border: 1px solid transparent;
  /*border border-transparent*/
}

/*Pagination Buttons - Hover */
.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
  /* color: #fff !important; */
  /*text-white*/
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  /*shadow*/
  font-weight: 700;
  /*font-bold*/
  border-radius: 0.25rem;
  /*rounded*/
  /* background: #667eea !important; */
  background: #667eea !important;
  color: #fff !important;
  /*bg-indigo-500*/
  border: 1px solid transparent;
  /*border border-transparent*/
}

/*Add padding to bottom border */
table.dataTable.no-footer {
  /* border-bottom: 1px solid #e2e8f0; */
  border-bottom: 1px solid white;
  /*border-b-1 border-gray-300*/
  margin-top: 0.75em;
  margin-bottom: 0.75em;
}

/*Change colour of responsive icon*/
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
  background-color: #667eea !important;
  /*bg-indigo-500*/
}

/* .dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
  color: white;
} */

select option[disabled]:first-child {
  color: #9a9a9a;
}
