/* General container styles */
.dashboard-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  /* Header styles */
  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 1.5rem;
    color: #444;
    margin-bottom: 15px;
  }
  
  /* Services grid layout */
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Service card styles */
  .service-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.2s ease;
  }
  
  .service-card:hover {
    transform: translateY(-10px); /* Adds hover effect */
  }
  
  .service-card h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
  }
  
  .service-card p {
    font-size: 1rem;
    margin: 5px 0;
  }
  
  /* Styling for order details and employee details sections */
  .order-details, .employee-details {
    margin-top: 15px;
    padding-top: 10px;
    border-top: 1px solid #f0f0f0;
  }
  
  h4 {
    font-size: 1.1rem;
    color: #333;
    margin-bottom: 10px;
  }
  
  /* Spinner loader styles */
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .spinner {
    border: 4px solid #f3f3f3; /* Light grey background */
    border-top: 4px solid #3498db; /* Blue color for spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .filter-container {
    margin-bottom: 20px;
  }
  
  #booking-id-filter {
    padding: 8px;
    width: 250px;
    margin-left: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  /* Styling for order details section */
.order-details {
    margin-top: 20px;
  }
  
  .order-details h4 {
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* List styling */
  .order-details ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .order-card {
    background-color: #f9f9f9;  /* Light background for card */
    padding: 15px;
    margin: 10px 0;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
  }
  
  .order-card:hover {
    background-color: #f0f0f0;  /* Slightly darker background on hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);  /* Increased shadow on hover */
  }
  
  /* Styling for individual order details */
  .order-card p {
    margin: 5px 0;
  }
  
  .order-card strong {
    color: #333;
  }
  
  .order-card p {
    color: #555;
  }
  