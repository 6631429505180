.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.button-group button {
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  background-color: #f8f9fa;
  border: 2px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.button-group button:hover {
  background-color: #e2e6ea;
  border-color: #aaa;
}

.button-group button:active {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transform: translateY(2px);
}

.button-group button.active {
  background-color: #ff00005c;
  color: white;
  border-color: #0056b3;
  box-shadow: 0 4px 6px rgba(0, 123, 255, 0.5);
}

.service-card {
  width: 86vw;
  background-color: #f8f9fa;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  margin: 20px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  /* transition: box-shadow 0.3s ease; */
}

.service-card:hover {
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.service-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.service-card p {
  width: auto;
  margin: 5px 0;
  font-size: 16px;
}

.order-detail {
  margin-left: 20px;
  padding: 10px;
  background-color: #e9ecef;
  border: 1px solid #ddd;
  border-radius: 5px;
}


.cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
 
  
 
  .inventory-card,
.inventory-total-card {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

.inventory-card:hover,
.inventory-total-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.employee-inventory {
  margin-top: 10px;
  padding: 10px;
  background-color: #e9ecef ;
  /* border-left: 4px solid #1890ff; */
  border-radius: 5px;
}


h3, h4 {
  color: #333;
}

p {
  margin: 4px 0;
  font-size: 16px;
}

/* No data message styling */
.nodata {
  margin-left: 30vw;
  margin-top: 10vh;
  text-align: center;
  font-weight: 700;
  border: 3px solid #333;
  padding: 11px;
}
/* .filter-container {
 
  margin-bottom: 20px;
  
  
} */

/* #id-filter {
  padding: 8px;
  width: 250px;
  margin-left: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
label{
  display: block;
} */

.filter-container {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  gap: 20px;
  /* margin: 20px auto; */
  padding: 10px;
  background-color: #f8f9fa;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
  width: 600px;
}

.filter-container label {
  font-size: 14px;
  font-weight: bold;
  color: #333;
  margin-right: 8px;
}

.filter-container input[type="text"],
.filter-container input[type="date"] {
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: all 0.3s ease;
  outline: none;
  width: 200px;
  max-width: 100%;
}

.filter-container input[type="text"]::placeholder {
  color: #aaa;
  font-style: italic;
}

.filter-container input[type="text"]:focus,
.filter-container input[type="date"]:focus {
  border-color: #0056b3;
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.3);
}

.filter-container input[type="date"] {
  background-color: #fff;
}
/* 
@media (max-width: 768px) {
  .filter-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  } */

  .filter-container input {
    width: 100%;
  }

